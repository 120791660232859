import React from "react"
import styles from "../components/header.module.css"
import Header from "../components/header"
import { Link } from "gatsby"

export default () => (
  <Header>
    <div className={styles.mainheading}>Aviva Melamed</div>
    <div className={styles.subheading}>Humanistic Integrative Counsellor</div>
    <div className={styles.box} />
    <div className={styles.menubox}>
      <ul className={styles.ul}>
        <li className={styles.li}>
          <Link to="/">Home</Link>
        </li>
        <li className={styles.li}>
          <Link to="/about">About Me</Link>
        </li>
        <li className={styles.li}>
          <Link to="/faq">FAQ</Link>
        </li>
        <li className={styles.li}>
          <Link to="/services">Services</Link>
        </li>
        <li className={styles.li}>
          <Link to="/contact">Contact Me</Link>
        </li>
      </ul>
    </div>
    <div className={styles.logo} />
    <div className={styles.homebox}>
      Are you looking to <b>build confidence</b> and <b>self-esteem</b>?<br />
      <br />
      Do you want help with <b>understanding</b> your <b>relationships</b>?
      <br />
      <br />
      Are you dealing with <b>loss, stress</b> or <b>depression</b>?<br />
      <br />
      Would you like to <b>improve communication</b> with loved ones or people
      you work with?
      <br />
      <br />
      Counselling may be suited to you. It gives you a <b>safe</b> and{" "}
      <b>confidential</b> space to talk things through. Rediscover who you are
      in a <b>confidential, non-judgemental</b> and <b>supportive</b>{" "}
      environment.
      <br />
      <br />
      I’m here to <b>help</b> you on your journey of <b>self-discovery</b>.
    </div>
    <div className={styles.homebottom}>
      Some of the issues that can be explored:
    </div>
    <div className={styles.bottomleft}>
      • relationship issues <br />• self-esteem <br />• family conflict <br />•
      loss <br />• grief <br />• anger <br /> • depression <br /> • self-harm <br />
    </div>
    <div className={styles.bottomright}>
      • domestic violence <br />• physical abuse <br />• sexual abuse <br />•
      OCD <br />• sexuality <br />• mental health <br />• cultural issues <br />• eating disorders  <br />
    </div>
  </Header>
)
